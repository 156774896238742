import { forwardRef, type ReactNode } from 'react';
import { type GlobalHTMLAttributes } from './types';

export type SubmitButtonProps = GlobalHTMLAttributes & {
  /**
   * Which design variant to render.
   *
   * @default filled
   */
  variant?: 'filled' | 'outlined';

  /**
   * The `accent` color can be used to add extra prominence to the button.
   * Use the `destructive` color for actions that require caution.
   *
   * @default neutral
   */
  color?: 'accent' | 'destructive' | 'neutral';

  /**
   * Use the small button in cards and other constrained containers.
   *
   * @default medium
   */
  size?: 'medium' | 'small';

  /**
   * Id of a form element that this button should be associated with. Defaults to the containing
   * form element.
   */
  form?: string;

  /**
   * The URL that processes the information submitted by the button, overriding the `action` attribute of the button's form.
   */
  formAction?: string;

  /**
   * Specifies the HTTP method used to submit the form, overriding the `method` attribute of the button's form.
   */
  formMethod?: 'post' | 'get';

  /**
   * The name of the button, submitted as a pair with the button's value as part of the form data.
   */
  name?: string;

  /**
   * The value associated with the button's `name` in the form data when the form is submitted using this button.
   */
  value?: string;

  /**
   * The button label.
   */
  children: ReactNode;

  /**
   * Disables the button. Avoid using disabled buttons whenever possible - instead show error messages explaining the next steps the user should take.
   */
  disabled?: boolean;

  /**
   * Renders a spinner in place of the button label and prevents activating the button again.
   */
  loading?: boolean;

  /**
   * Label for screen readers while showing the loading spinner.
   */
  loadingLabel?: string;

  /**
   * @default 'submit'
   */
  type?: 'submit' | 'button';

  /**
   * Called when the button is clicked.
   *
   * Prefer to place the button within a `<form> and use the `onSubmit` event on the form instead.
   */
  onClick?: React.MouseEventHandler<HTMLButtonElement>;

  onFocus?: React.FocusEventHandler<HTMLButtonElement>;
  onBlur?: React.FocusEventHandler<HTMLButtonElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLButtonElement>;
  onKeyUp?: React.KeyboardEventHandler<HTMLButtonElement>;
  onPointerEnter?: React.PointerEventHandler<HTMLButtonElement>;
  onPointerMove?: React.PointerEventHandler<HTMLButtonElement>;
  onPointerDown?: React.PointerEventHandler<HTMLButtonElement>;
  onPointerUp?: React.PointerEventHandler<HTMLButtonElement>;
  onPointerLeave?: React.PointerEventHandler<HTMLButtonElement>;
};

/**
 * SubmitButton should be used to submit a form.
 *
 * Prefer handling the form `onSubmit` event rather than `onClick` on the submit button.
 */
export const SubmitButton = forwardRef<HTMLButtonElement, SubmitButtonProps>(
  function SubmitButton(
    {
      color,
      size,
      loading,
      loadingLabel,
      disabled,
      children,
      variant = 'filled',
      className = '',
      ...props
    }: SubmitButtonProps,
    ref: React.ForwardedRef<HTMLButtonElement>
  ) {
    const isLoading = !disabled && loading;
    return (
      <button
        ref={ref}
        type="submit"
        {...props}
        onClick={(event) => {
          if (disabled || loading) {
            event.preventDefault();
          } else if (props.onClick) {
            props.onClick(event);
          }
        }}
        className={`button-${variant} ${className}`.trim()}
        aria-disabled={disabled || loading || undefined}
        data-loading={isLoading || undefined}
        aria-live={typeof loading === 'boolean' ? 'polite' : undefined}
        data-color={color === 'neutral' ? undefined : color}
        data-size={size === 'small' ? 'small' : undefined}
      >
        {isLoading ? (
          <>
            <span className="invisible">{children}</span>
            <progress className="spinner" aria-label={loadingLabel} />
          </>
        ) : (
          children
        )}
      </button>
    );
  }
);
