import { Icon } from '@volvo-cars/react-icons';

export interface ErrorMessageProps {
  errorMessage?: string;
  id: string;
  className?: string;
}

export function ErrorMessage({
  errorMessage,
  id,
  className = '',
}: ErrorMessageProps) {
  if (!errorMessage) return null;
  return (
    <p
      id={id}
      className={`micro text-feedback-red flex items-center ${className}`.trim()}
      role="alert"
    >
      <Icon
        icon="exclamation-mark-circled"
        size={12}
        className="mr-8"
        color="feedback-red"
      />
      {errorMessage}
    </p>
  );
}
