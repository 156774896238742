import { createContext } from 'react';
import { ErrorMessage } from './error-message';
import { Hint } from './hint';
import { type GlobalHTMLAttributes } from './types';
import { useIds } from './use-ids';

interface BaseRadioGroupProps extends GlobalHTMLAttributes {
  /**
   * The legend to show above the radio group.
   */
  legend: React.ReactNode;

  /**
   * The name of the radios to use when submitting the form.
   */
  name: string;

  /**
   * Additional hint or description for the entire radio group.
   */
  hint?: React.ReactNode;

  /**
   * The radios.
   */
  children: React.ReactNode;

  /**
   * Makes the radio group required.
   */
  required?: boolean;

  /**
   * Fires when a radio is selected.
   */
  onChange?: React.ChangeEventHandler<HTMLInputElement>;

  /**
   * Fires if the radio group fails validation on form submit.
   */
  onInvalid?: React.FormEventHandler<HTMLFieldSetElement>;

  /**
   * Disable all radios in the group. Use sparingly as it can be non-obvious to users why an input
   * has been disabled. Prefer showing validation messages and hints instead.
   *
   * @default false
   */
  disabled?: boolean;

  /**
   * Makes the radios in the group read-only. Use sparingly, it's often preferred to present the
   * data as regular text or in a table instead.
   *
   * @default false
   */
  readOnly?: boolean;

  /**
   * Id of a form element that the radios should be associated with. Defaults to the containing
   * form element.
   */
  form?: string;

  enterKeyHint?: 'done' | 'go' | 'next' | 'previous' | 'search' | 'send';

  /**
   * Set the error message of a radio group and mark it invalid.
   */
  errorMessage?: string;

  /**
   * Force the radio group to be invalid.
   *
   * @default false
   */
  'aria-invalid'?: boolean;
}

type ControlledProps = {
  /**
   * Select the radio with this value. Either `value` or `defaultValue` must be set.
   *
   * Makes the radio group controlled.
   */
  value: string;

  onChange: React.ChangeEventHandler<HTMLInputElement>;

  defaultValue?: never;
};

type UncontrolledProps = {
  /**
   * Select the uncontrolled radio with this value by default. Either `defaultValue` or `value` must
   * be set.
   */
  defaultValue: string;

  value?: never;
};

export type RadioGroupControlledProps = BaseRadioGroupProps & ControlledProps;
export type RadioGroupUncontrolledProps = BaseRadioGroupProps &
  UncontrolledProps;
export type RadioGroupProps = BaseRadioGroupProps &
  (ControlledProps | UncontrolledProps);

/**
 * Use a Radio Group when users should select a single option from a list.
 */
export function RadioGroup({
  legend,
  id,
  hint,
  name,
  children,
  required,
  defaultValue,
  value,
  hidden,
  dir,
  lang,
  translate,
  slot,
  errorMessage: errorMessageProp,
  onChange,
  form,
  enterKeyHint,
  readOnly,
  // @ts-expect-error `isValid` from VCC UI `useField` hook is not explicitly supported,
  // but included for ease of migration until there is a convenient migration path for form validation
  isValid,
  ...props
}: RadioGroupProps) {
  const { inputId, hintId, errorId } = useIds(id);
  // Hide error message and error state when input is disabled
  const errorMessage = !props.disabled ? errorMessageProp : undefined;
  const ariaInvalid =
    props['aria-invalid'] || isValid === false || !!errorMessage;
  return (
    <fieldset
      {...props}
      hidden={hidden}
      dir={dir}
      lang={lang}
      translate={translate}
      slot={slot}
      form={form}
      id={inputId}
      role="radiogroup"
      aria-invalid={ariaInvalid ? true : undefined}
      aria-errormessage={errorMessage ? errorId : undefined}
      aria-describedby={hint ? hintId : undefined}
      // https://adrianroselli.com/2022/02/support-for-marking-radio-buttons-required-invalid.html
      aria-required={required ? true : undefined}
    >
      <legend className="mb-4">{legend}</legend>
      {hint && (
        <Hint id={hintId} className="mb-16">
          {hint}
        </Hint>
      )}
      <div className="stack-8" hidden={hidden}>
        <ErrorMessage
          errorMessage={errorMessage}
          id={errorId}
          className="mb-16"
        />
        <RadioContextProvider
          value={{
            defaultValue,
            enterKeyHint,
            form,
            name,
            onChange,
            readOnly,
            required,
            value,
          }}
        >
          {children}
        </RadioContextProvider>
      </div>
    </fieldset>
  );
}

export type RadioContextValue = Pick<
  RadioGroupProps,
  | 'defaultValue'
  | 'enterKeyHint'
  | 'form'
  | 'name'
  | 'onChange'
  | 'readOnly'
  | 'required'
  | 'value'
>;

export const RadioContext: React.Context<RadioContextValue> = createContext(
  {} as RadioContextValue
);

export const RadioContextProvider = RadioContext.Provider;
