// TODO: Fix eslint issues the next time this file is edited.
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { useTranslate } from '@volvo-cars/react-shared-translations';
import { Text, View } from 'vcc-ui';
import { Link, LinkBaseProps } from '../Link';
import { getUnitlessFontSize } from '../utils/getFontSize';

export interface BreadcrumbsProps {
  trail: LinkBaseProps[];
  currentTitle: string;
  'aria-label'?: string;
}

const padding = (baselineGrid: number) =>
  `${3 * baselineGrid}px 0px ${2 * baselineGrid}px ${2 * baselineGrid}px`;

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({
  trail,
  currentTitle,
  'aria-label': ariaLabel,
}) => {
  const lastLink = trail[trail.length - 1];
  const [isBreadcrumbCollapsed, setIsBreadcrumbCollapsed] = useState(false);
  const ref = useRef<HTMLElement>(null);

  ariaLabel =
    useTranslate('react-breadcrumbs.ariaLabel', {
      disabled: !!ariaLabel,
    }) || ariaLabel;

  /**
   * Number of total characters in the breadcrumb
   */
  const chars =
    trail.reduce((acc, link) => {
      return acc + link.title.length;
    }, 0) +
    currentTitle.length +
    // number of separators `/`
    trail.length;
  /**
   * @returns  total padding in the Breadcrumb, breadcrumb left padding + all links paddings
   */
  const totalPadding = (baselineGrid: number) =>
    2 * baselineGrid + 2 * baselineGrid * trail.length;

  // Used to hide with `visibility:hidden` to prevent tabbing after hydration (since tabbing still works with fontSize:0)
  useEffect(() => {
    if (!ref) return;
    const element = ref.current;
    if (!element) return;
    const resizeObserver = new ResizeObserver(() => {
      const value = window
        .getComputedStyle(element)
        .getPropertyValue('font-size');
      setIsBreadcrumbCollapsed(value === '0px');
    });
    resizeObserver.observe(element);
    return () => {
      resizeObserver.unobserve(element);
    };
  }, []);
  return (
    <View
      ref={ref}
      as="nav"
      aria-label={ariaLabel}
      extend={({
        theme: {
          baselineGrid,
          typeScale: { columbus, bates },
        },
      }) => ({
        // 10 is approximately one character length with 16px font-size, can't
        // use `ch` due to to the fact that we change fontSize to hide which
        // changes `ch`  and messes the padding collapse
        '--breadcrumb-width': `calc(${chars * 10}px + ${totalPadding(
          baselineGrid
        )}px)`,
        // returns 1px if breadcrumb width is larger than 90vw and 1px for the
        // opposite
        '--should-links-collapse-px':
          'clamp(0px, calc(var(--breadcrumb-width) - 90vw), 1px)',
        // convert [0px, 1px] to [0rem, 1rem]. x*inf - 1000px, if x = 0 we get a
        // negative value and that gets clamped to 0rem, if x = 1px we get a very
        // large positive value and that gets clamped to 1rem
        '--should-links-collapse-rem':
          'clamp(0rem, calc(calc(var(--should-links-collapse-px) * 10000) - 1000px), 1rem)',
        // inverse
        '--should-links-not-collapse-px':
          'calc(calc(var(--should-links-collapse-px) * -1) + 1px)',
        '--should-links-not-collapse-rem':
          'calc(calc(var(--should-links-collapse-rem) * -1) + 1rem)',
        // columbus standard
        '--collapsable-font-size-c': `calc(var(--should-links-not-collapse-rem) * ${getUnitlessFontSize(
          columbus
        )})`,
        // placeholder, used to be read  after hydration to determine if the breadcrumb is collapsed or not
        fontSize: 'var(--collapsable-font-size-c)',
        '--collapsable-padding': `calc(var(--should-links-not-collapse-px) * ${baselineGrid})`,
      })}
    >
      <View
        as="ol"
        direction="row"
        extend={({ theme: { baselineGrid } }) => ({
          untilM: { display: 'none' },
          padding: padding(baselineGrid),
          margin: 0,
        })}
      >
        {trail.map((link, index) => (
          <Link
            {...link}
            key={index}
            isBreadcrumbCollapsed={isBreadcrumbCollapsed}
            index={index}
            trailLength={trail.length}
          />
        ))}
        <CurrentTitle title={currentTitle} />
      </View>
      <View
        as="ol"
        extend={({ theme: { baselineGrid } }) => ({
          fromM: { display: 'none' },
          padding: padding(baselineGrid),
          margin: 0,
        })}
      >
        <Link {...lastLink} withBackArrow index={trail.length - 1} />
      </View>
    </View>
  );
};

const CurrentTitle: React.FC<{
  title: BreadcrumbsProps['currentTitle'];
}> = ({ title }) => {
  return (
    <View as="li" aria-current="page">
      <Text>{title}</Text>
    </View>
  );
};
