import { forwardRef } from 'react';
import { cssMerge } from '@volvo-cars/css/utils';
import { ErrorMessage } from './error-message';
import { Hint } from './hint';
import { type TextLikeProps } from './types';
import { useIds } from './use-ids';

interface BaseTextInputProps extends TextLikeProps {
  /**
   * Regular expression that the input value must match.
   */
  pattern?: string;

  /**
   * Id of a `<datalist>` element with a list of predefined values to suggest to the user.
   */
  list?: string;

  /**
   * Specifies the visible width, in characters, of the input.
   */
  size?: number;

  /**
   * Minimum length of the input value.
   */
  minLength?: number;

  /**
   * Maximum length of the input value.
   */
  maxLength?: number;

  /**
   * Hint the browser about the appropriate virtual keyboard for the type of expected data.
   */
  inputMode?: 'none' | 'text' | 'numeric' | 'decimal';

  /**
   * Controls whether and how text input is automatically capitalized as it is entered by the user.
   */
  autoCapitalize?: 'none' | 'sentences' | 'words' | 'characters';

  /**
   * Controls whether the element may be checked for spelling errors.
   */
  spellCheck?: boolean;

  /**
   * What type of information to autocomplete in the input.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
   */
  autoComplete?:
    | 'off'
    | 'on'
    | 'bday'
    | 'bday-day'
    | 'bday-month'
    | 'bday-year'
    | 'name'
    | 'family-name'
    | 'given-name'
    | 'username'
    | 'one-time-code'
    | 'organization-title'
    | 'organization'
    | 'street-address'
    | 'address-line1'
    | 'address-line2'
    | 'address-line3'
    | 'address-level4'
    | 'address-level3'
    | 'address-level2'
    | 'address-level1'
    | 'country'
    | 'country-name'
    | 'postal-code'
    | 'cc-name'
    | 'cc-number'
    | 'cc-exp'
    | 'cc-exp-month'
    | 'cc-exp-year'
    | 'cc-csc'
    | 'cc-type'
    | 'transaction-currency'
    | 'transaction-amount'
    | 'language'
    | 'sex';

  /**
   * Inject an element into the input field, displayed at the end of the input.
   * Usually used to display a custom `Icon` or `IconButton`.
   */
  contentAfter?: React.ReactNode;
}

type ControlledProps = {
  /**
   * Value of the input.
   *
   * Makes the input controlled.
   */
  value: string;

  /**
   * Fires immediately when the input’s value is changed by the user (for example, it fires on every keystroke).
   */
  onChange: React.ChangeEventHandler<HTMLInputElement>;

  defaultValue?: never;
};

type UncontrolledProps = {
  /**
   * Default value of an uncontrolled input.
   */
  defaultValue?: string;

  /**
   * Fires immediately when the input’s value is changed by the user (for example, it fires on every keystroke).
   */
  onChange?: React.ChangeEventHandler<HTMLInputElement>;

  value?: never;
};

export type TextInputUncontrolledProps = BaseTextInputProps & UncontrolledProps;
export type TextInputControlledProps = BaseTextInputProps & ControlledProps;
export type TextInputProps = BaseTextInputProps &
  (ControlledProps | UncontrolledProps);

/**
 * TextInput should be used when the information is known by the user.
 * It can be used by itself or in combination with any other inputs.
 */
export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  function TextInput(props, ref) {
    return <TextLikeInput {...props} type="text" ref={ref} />;
  }
);

type TextLikeInputProps = Omit<TextInputProps, 'inputMode' | 'autoComplete'> & {
  type: string;
  autoComplete?: string;
  inputMode?:
    | 'none'
    | 'text'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | 'search';
};

/**
 * TextInput should be used when the information is known by the user.
 * It can be used by itself or in combination with any other inputs.
 */
export const TextLikeInput = forwardRef<HTMLInputElement, TextLikeInputProps>(
  function TextLikeInput(
    {
      hint,
      id,
      label,
      hidden,
      dir,
      errorMessage: errorMessageProp,
      lang,
      translate,
      slot,
      // @ts-expect-error `isValid` from VCC UI `useField` hook is not explicitly supported,
      // but included for ease of migration until there is a convenient migration path for form validation
      isValid,
      className,
      style,
      contentAfter,
      ...props
    }: TextLikeInputProps,
    ref: React.ForwardedRef<HTMLInputElement>
  ) {
    const { inputId, errorId, hintId } = useIds(id);

    // Hide error message and error state when input is disabled
    const errorMessage = !props.disabled ? errorMessageProp : undefined;
    const ariaInvalid =
      props['aria-invalid'] || isValid === false || !!errorMessage;

    const inputProps = {
      placeholder: ' ',
      ...props,
      ref,
      id: inputId,
      'aria-invalid': ariaInvalid ? true : undefined,
      'aria-errormessage': errorMessage ? errorId : undefined,
      'aria-describedby': hint ? hintId : undefined,
    };

    return (
      <div
        id={id}
        className={cssMerge('input-floating-label', className)}
        hidden={hidden}
        dir={dir}
        lang={lang}
        translate={translate}
        slot={slot}
        style={style}
      >
        <label htmlFor={inputId}>{label}</label>

        {contentAfter ? (
          <div
            className="input"
            data-input-delegate
            aria-invalid={ariaInvalid ? true : undefined}
          >
            <input {...inputProps} className="bg-transparent" />
            {contentAfter}
          </div>
        ) : (
          <input {...inputProps} />
        )}

        <ErrorMessage
          errorMessage={errorMessage}
          id={errorId}
          className="mt-4"
        />

        {hint && (
          <Hint id={hintId} className="mt-4">
            {hint}
          </Hint>
        )}
      </div>
    );
  }
);
