import { forwardRef, useState } from 'react';
import { IconButton } from '@volvo-cars/react-icons';
import { TextLikeInput } from './text-input';
import { type RangeLikeProps } from './types';

interface BaseDateTimeInputProps extends RangeLikeProps {
  /**
   * The earliest date time to accept, in the `YYYY-MM-DDTHH:mm` format.
   */
  min?: string;

  /**
   * The latest date time to accept, in the `YYYY-MM-DDTHH:mm` format.
   */
  max?: string;

  /**
   * What type of information to autocomplete in the input.
   */
  autoComplete?: string;
}

type ControlledProps = {
  /**
   * Value of the input.
   *
   * Makes the input controlled.
   */
  value: string;

  /**
   * Fires immediately when the input’s value is changed by the user (for example, it fires on every keystroke).
   */
  onChange: React.ChangeEventHandler<HTMLInputElement>;

  defaultValue?: never;
};

type UncontrolledProps = {
  /**
   * Default value of an uncontrolled input.
   */
  defaultValue?: string;

  /**
   * Fires immediately when the input’s value is changed by the user (for example, it fires on every keystroke).
   */
  onChange?: React.ChangeEventHandler<HTMLInputElement>;

  value?: never;
};

export type DateTimeInputUncontrolledProps = BaseDateTimeInputProps &
  UncontrolledProps;
export type DateTimeInputControlledProps = BaseDateTimeInputProps &
  ControlledProps;
export type DateTimeInputProps = BaseDateTimeInputProps &
  (ControlledProps | UncontrolledProps);

/**
 * Lets the users select a date from the native calendar dropdown.
 * Should be used when it is helpful for the user to see what weekday a selected date is.
 *
 * Browser compatibility:
 * - https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/datetime-local#browser_compatibility
 * - As of 24 Sep 2024, Safari and Firefox do not show the visual date picker, but the functionality is still available via the keyboard.
 * - Be careful with this component on pages with broader public audiences.
 */
export const DateTimeInput = forwardRef<HTMLInputElement, DateTimeInputProps>(
  function DateTimeInput(
    { value, onChange, onBlur, defaultValue, ...props }: DateTimeInputProps,
    ref: React.ForwardedRef<HTMLInputElement>
  ) {
    const [isBlank, setIsBlank] = useState(!value && !defaultValue);
    function updateIsBlank(event: React.FormEvent<HTMLInputElement>) {
      // Use constraints validation to determine if the input is completely blank.
      // `value` will be empty even if the input is partially filled, but `badInput` will be true.
      const { validity } = event.currentTarget;
      const initiallyRequired = event.currentTarget.required;
      if (!initiallyRequired) {
        event.currentTarget.required = true;
      }
      setIsBlank(validity.valueMissing && !validity.badInput);
      if (!initiallyRequired) {
        event.currentTarget.required = false;
      }
    }
    return (
      <TextLikeInput
        {...props}
        value={value}
        defaultValue={defaultValue}
        type="datetime-local"
        ref={ref}
        onBlur={(event) => {
          onBlur?.(event);
          updateIsBlank(event);
        }}
        onChange={(event) => {
          onChange?.(event);
          updateIsBlank(event);
        }}
        data-blank={isBlank ? '' : undefined}
        contentAfter={
          <IconButton
            icon="calendar"
            className="ml-8 -mr-8"
            variant="clear"
            // No need for a separate button for screen reader users,
            // they will interact with the native form controls.
            aria-hidden
            tabIndex={-1}
            aria-label=""
            onClick={(event) => {
              const input = event.currentTarget.parentElement?.querySelector(
                'input[type=datetime-local]'
              ) as HTMLInputElement | null;
              if (input) {
                try {
                  input.showPicker();
                  // Safari has the `showPicker` method but it does nothing and does not throw
                  // so we can't use feature detection.
                  if (
                    /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
                  ) {
                    throw new Error();
                  }
                } catch (error) {
                  // Use brute force to trigger the date picker.
                  input.click();
                  input.focus();
                }
              }
            }}
          />
        }
      />
    );
  }
);
