import { Children, forwardRef, isValidElement, useState } from 'react';
import { cssMerge } from '@volvo-cars/css/utils';
import { ErrorMessage } from './error-message';
import { Hint } from './hint';
import { type BaseInputProps, type GlobalHTMLAttributes } from './types';
import { useIds } from './use-ids';

interface BaseSelectProps
  extends GlobalHTMLAttributes,
    BaseInputProps<HTMLSelectElement> {
  children: React.ReactNode;

  /**
   * Makes the select required.
   */
  required?: boolean;

  enterKeyHint?: 'done' | 'go' | 'next' | 'previous' | 'search' | 'send';

  /**
   * The name of the input to use when submitting the form.
   */
  name: string;

  /**
   * A concise label for the input.
   */
  label: string;

  /**
   * What type of information to autocomplete in the select.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
   */
  autoComplete?:
    | 'off'
    | 'on'
    | 'organization-title'
    | 'organization'
    | 'address-level4'
    | 'address-level3'
    | 'address-level2'
    | 'address-level1'
    | 'country'
    | 'country-name'
    | 'cc-exp-month'
    | 'cc-exp-year'
    | 'cc-type'
    | 'transaction-currency'
    | 'language'
    | 'sex';

  /**
   * Set the error message of a select and mark it invalid.
   */
  errorMessage?: string;

  /**
   * Force the input to be invalid.
   *
   * @default false
   */
  'aria-invalid'?: boolean;
}

type ControlledProps = {
  /**
   * Value of the input.
   *
   * Makes the input controlled.
   */
  value: string | string[];

  /**
   * Fires when the select’s value is changed.
   */
  onChange: React.ChangeEventHandler<HTMLSelectElement>;

  defaultValue?: never;
};

type UncontrolledProps = {
  /**
   * Default value of an uncontrolled input.
   */
  defaultValue?: string | string[];

  /**
   * Fires immediately when the input’s value is changed by the user (for example, it fires on every keystroke).
   */
  onChange?: React.ChangeEventHandler<HTMLSelectElement>;

  value?: never;
};

export type SelectUncontrolledProps = BaseSelectProps & UncontrolledProps;
export type SelectControlledProps = BaseSelectProps & ControlledProps;
export type SelectProps = BaseSelectProps &
  (ControlledProps | UncontrolledProps);

/**
 * Select lets the users select one option from a predefined list of 3 or more options. When you have fewer
 * options, consider using a RadioGroup.
 *
 * Review the [React docs](https://react.dev/reference/react-dom/components/select) for more details
 * on using selects in React.
 */
export const Select = forwardRef<HTMLSelectElement, SelectProps>(
  function Select(
    {
      hint,
      id,
      label,
      hidden,
      dir,
      errorMessage: errorMessageProp,
      lang,
      translate,
      slot,
      value,
      defaultValue,
      children,
      // @ts-expect-error `isValid` from VCC UI `useField` hook is not explicitly supported,
      // but included for ease of migration until there is a convenient migration path for form validation
      isValid,
      className,
      ...props
    }: SelectProps,
    ref: React.ForwardedRef<HTMLSelectElement>
  ) {
    let hasEmptyChild = false;
    Children.forEach(children, (child) => {
      if (
        isValidElement(child) &&
        child.type === 'option' &&
        child.props.value === ''
      ) {
        hasEmptyChild = true;
      }
    });
    const [isUncontrolledBlank, setIsUncontrolledBlank] = useState(
      !defaultValue
    );
    const controlled = typeof value === 'string';
    const { inputId, errorId, hintId } = useIds(id);
    // Hide error message and error state when input is disabled
    const errorMessage = !props.disabled ? errorMessageProp : undefined;
    const ariaInvalid =
      props['aria-invalid'] || isValid === false || !!errorMessage;
    const isBlank = controlled ? !value : isUncontrolledBlank;
    return (
      <div
        id={id}
        className={cssMerge('input-floating-label', className)}
        hidden={hidden}
        dir={dir}
        lang={lang}
        translate={translate}
        slot={slot}
      >
        <label htmlFor={inputId}>{label}</label>
        <select
          {...props}
          ref={ref}
          id={inputId}
          onChange={
            controlled
              ? props.onChange
              : (event) => {
                  props.onChange?.(event);
                  setIsUncontrolledBlank(!event.target.value);
                }
          }
          value={value}
          defaultValue={defaultValue}
          data-blank={isBlank ? '' : undefined}
          aria-invalid={ariaInvalid ? 'true' : undefined}
          aria-errormessage={errorMessage ? errorId : undefined}
          aria-describedby={hint ? hintId : undefined}
        >
          {/*
          By default in browsers, the first <option> of a blank <select> will be selected.
          We create an option with the select label text and an empty value instead of
          automatically selecting the first option.
          This option is hidden as soon as another option is selected or if any other option is
          selected by deafult.
          Due to issues in Safari on iPads we don't dynamically remove it from the DOM when the
          select is changed but instead use `hidden` and `disabled` to hide it.
        */}
          {!defaultValue && !hasEmptyChild && (
            <option value="" hidden={!isBlank} disabled={!isBlank}>
              {label}
            </option>
          )}
          {children}
        </select>
        <ErrorMessage
          errorMessage={errorMessage}
          id={errorId}
          className="mt-4"
        />
        {hint && (
          <Hint id={hintId} className="mt-4">
            {hint}
          </Hint>
        )}
      </div>
    );
  }
);
