import { forwardRef } from 'react';
import { cssMerge } from '@volvo-cars/css/utils';
import { ErrorMessage } from './error-message';
import { Hint } from './hint';
import { type BaseInputProps, type GlobalHTMLAttributes } from './types';
import { useIds } from './use-ids';

interface BaseTextAreaProps
  extends GlobalHTMLAttributes,
    BaseInputProps<HTMLTextAreaElement> {
  /**
   * The name of the textarea to use when submitting the form.
   */
  name: string;

  /**
   * Placeholder text to show in the textarea when it is empty.
   */
  placeholder?: string;

  /**
   * Minimum length of the textarea value.
   */
  minLength?: number;

  /**
   * Maximum length of the textarea value.
   */
  maxLength?: number;

  /**
   * The number of visible text lines to show in the textarea.
   *
   * @default 2
   */
  rows?: number;

  /**
   * Controls whether and how text input is automatically capitalized as it is entered by the user.
   */
  autoCapitalize?: 'none' | 'sentences' | 'words' | 'characters';

  /**
   * Controls whether the element may be checked for spelling errors.
   */
  spellCheck?: boolean;

  /**
   * Enable or disable autocomplete in the textarea.
   */
  autoComplete?: 'off' | 'on';

  /**
   * Set the error message of a textarea and mark it invalid.
   */
  errorMessage?: string;

  /**
   * Force the textarea to be invalid.
   *
   * @default false
   */
  'aria-invalid'?: boolean;
}

type ControlledProps = {
  /**
   * Value of the textarea.
   *
   * Makes the textarea controlled.
   */
  value: string;

  /**
   * Fires immediately when the textarea's value is changed by the user (for example, it fires on every keystroke).
   */
  onChange: React.ChangeEventHandler<HTMLTextAreaElement>;

  defaultValue?: never;
};

type UncontrolledProps = {
  /**
   * Default value of an uncontrolled textarea.
   */
  defaultValue?: string;

  /**
   * Fires immediately when the textarea's value is changed by the user (for example, it fires on every keystroke).
   */
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;

  value?: never;
};

export type TextAreaUncontrolledProps = BaseTextAreaProps & UncontrolledProps;
export type TextAreaControlledProps = BaseTextAreaProps & ControlledProps;
export type TextAreaProps = BaseTextAreaProps &
  (ControlledProps | UncontrolledProps);

/**
 * TextArea should be used when the information is known by the user.
 * It can be used by itself or in combination with any other inputs.
 */
export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  function TextArea(
    {
      hint,
      id,
      label,
      hidden,
      dir,
      errorMessage: errorMessageProp,
      lang,
      translate,
      slot,
      // @ts-expect-error `isValid` from VCC UI `useField` hook is not explicitly supported,
      // but included for ease of migration until there is a convenient migration path for form validation
      isValid,
      className,
      style,
      ...props
    }: TextAreaProps,
    ref: React.ForwardedRef<HTMLTextAreaElement>
  ) {
    const { inputId, errorId, hintId } = useIds(id);
    // Hide error message and error state when input is disabled
    const errorMessage = !props.disabled ? errorMessageProp : undefined;
    const ariaInvalid =
      props['aria-invalid'] || isValid === false || !!errorMessage;
    return (
      <div
        id={id}
        className={cssMerge('input-floating-label', className)}
        hidden={hidden}
        dir={dir}
        lang={lang}
        translate={translate}
        slot={slot}
        style={style}
      >
        <label htmlFor={inputId}>{label}</label>
        <textarea
          // Needed to enable the :placeholder-shown pseudo-class for CSS
          // floating labels.
          placeholder=" "
          {...props}
          ref={ref}
          id={inputId}
          aria-invalid={ariaInvalid ? true : undefined}
          aria-errormessage={errorMessage ? errorId : undefined}
          aria-describedby={hint ? hintId : undefined}
        />
        <ErrorMessage
          errorMessage={errorMessage}
          id={errorId}
          className="mb-4"
        />
        {hint && (
          <Hint id={hintId} className="mt-4">
            {hint}
          </Hint>
        )}
      </div>
    );
  }
);
