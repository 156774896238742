import { forwardRef } from 'react';
import { TextLikeInput } from './text-input';
import { type TextLikeProps } from './types';

interface BaseTelInputProps extends TextLikeProps {
  /**
   * What type of information to autocomplete in the input.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
   */
  autoComplete?:
    | 'off'
    | 'tel'
    | 'tel-country-code'
    | 'tel-national'
    | 'tel-area-code'
    | 'tel-local';

  /**
   * Minimum length of the telephone number.
   */
  minLength?: number;

  /**
   * Maximum length of the telephone number.
   */
  maxLength?: number;

  /**
   * Specifies the visible width, in characters, of the input.
   */
  size?: number;

  /**
   * Regular expression that the telephone number must match.
   */
  pattern?: string;

  /**
   * Id of a `<datalist>` element with a list of predefined values to suggest to the user.
   */
  list?: string;

  /**
   * Inject an element into the input field, displayed at the end of the input.
   * Usually used to display a custom `Icon` or `IconButton`.
   */
  contentAfter?: React.ReactNode;
}

type ControlledProps = {
  /**
   * Value of the input.
   *
   * Makes the input controlled.
   */
  value: string;

  /**
   * Fires immediately when the input’s value is changed by the user (for example, it fires on every keystroke).
   */
  onChange: React.ChangeEventHandler<HTMLInputElement>;

  defaultValue?: never;
};

type UncontrolledProps = {
  /**
   * Default value of an uncontrolled input.
   */
  defaultValue?: string;

  /**
   * Fires immediately when the input’s value is changed by the user (for example, it fires on every keystroke).
   */
  onChange?: React.ChangeEventHandler<HTMLInputElement>;

  value?: never;
};

export type TelInputUncontrolledProps = BaseTelInputProps & UncontrolledProps;
export type TelInputControlledProps = BaseTelInputProps & ControlledProps;
export type TelInputProps = BaseTelInputProps &
  (ControlledProps | UncontrolledProps);

/**
 * TelInput should be used whenever the user needs to enter a phone number.
 *
 * It's identical to a TextInput, but with appropriate autocomplete and mobile
 * keyboard attributes.
 */
export const TelInput = forwardRef<HTMLInputElement, TelInputProps>(
  function TelInput(
    props: TelInputProps,
    ref: React.ForwardedRef<HTMLInputElement>
  ) {
    return <TextLikeInput {...props} type="tel" ref={ref} />;
  }
);
