import React from 'react';
import { useTracker } from '@volvo-cars/tracking';
import { Click, Icon, Text, View } from 'vcc-ui';
import { getUnitlessFontSize } from '../utils/getFontSize';

export interface LinkBaseProps {
  href: string;
  title: string;
  trackEventLabel?: string;
}

export const Link: React.FC<
  LinkBaseProps & {
    index: number;
    withBackArrow?: boolean;
    trailLength?: number;
    isBreadcrumbCollapsed?: boolean;
  }
> = ({
  href,
  title,
  trackEventLabel,
  index,
  withBackArrow = false,
  trailLength = 0,
  isBreadcrumbCollapsed,
}) => {
  const tracker = useTracker({}, { ga3: { eventAction: 'click' } });
  const onClick = () => {
    tracker.interaction({
      eventAction: 'link|click',
      eventLabel: trackEventLabel || `${index} | ${title}`,
    });
  };
  const collapsable = index !== 0 && index !== trailLength - 1;
  return (
    <View
      as="li"
      direction="row"
      extend={{
        // prevent tabbing when hiding with fontSize
        visibility:
          isBreadcrumbCollapsed && collapsable && index !== trailLength - 2
            ? 'hidden'
            : 'visible',
      }}
    >
      <Click
        href={href}
        onClick={onClick}
        extend={({ theme }) => ({
          display: 'flex',
          color: 'foreground.primary',
          ':focus-visible': {
            color: 'foreground.secondary',
            '& img': {
              content: `url(${theme.getIcon?.(
                'navigation-arrowback-16',
                'secondary'
              )})`,
            },
          },
          extend: {
            condition: !withBackArrow,
            style: {
              color: 'foreground.secondary',
              ':hover': {
                color: 'foreground.action',
              },
            },
          },
        })}
      >
        {withBackArrow && (
          <View paddingRight={1} justifyContent="center" aria-hidden>
            <Icon type="navigation-arrowback-16" />
          </View>
        )}
        <Text
          extend={{
            color: 'inherit',
            extend: [
              {
                condition: index !== 0,
                style: {
                  fromM: {
                    maxWidth: '20ch',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  },
                },
              },
              {
                condition: collapsable,
                style: {
                  fromM: {
                    fontSize: 'var(--collapsable-font-size-c)!important',
                  },
                },
              },
            ],
          }}
        >
          {title}
        </Text>
        {collapsable && index === trailLength - 2 ? (
          <Text
            aria-hidden
            extend={({
              theme: {
                typeScale: { columbus },
              },
            }) => ({
              color: 'inherit',
              fontSize: `calc(var(--should-links-collapse-rem) * ${getUnitlessFontSize(
                columbus
              )})!important`,
            })}
          >
            ...
          </Text>
        ) : null}
      </Click>
      {!withBackArrow && (
        <Separator collapsable={collapsable && index !== trailLength - 2} />
      )}
    </View>
  );
};

const Separator: React.FC<{ collapsable: boolean }> = ({ collapsable }) => {
  return (
    <View
      extend={({ theme: { baselineGrid } }) => ({
        paddingLeft: baselineGrid,
        paddingRight: baselineGrid,
        fromM: {
          extend: {
            condition: collapsable,
            style: {
              paddingLeft: `var(--collapsable-padding)!important`,
              paddingRight: `var(--collapsable-padding)!important`,
            },
          },
        },
      })}
      aria-hidden
      justifyContent="center"
    >
      <Text
        foreground="foreground.secondary"
        extend={{
          userSelect: 'none',
          fromM: {
            extend: {
              condition: collapsable,
              style: { fontSize: 'var(--collapsable-font-size-c)!important' },
            },
          },
        }}
      >
        /
      </Text>
    </View>
  );
};
