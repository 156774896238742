import { useId } from 'react';

export function useIds(passedId: string | undefined) {
  const generatedId = useId();
  const rootId = passedId || generatedId;
  return {
    rootId,
    inputId: rootId + '-input',
    errorId: rootId + '-error',
    hintId: rootId + '-hint',
  };
}
