import { useEffect } from 'react';

interface BrowserCompatKeys {
  [key: string]: string;
}
const keyValue: BrowserCompatKeys = {
  Down: 'ArrowDown',
  Up: 'ArrowUp',
  Left: 'ArrowLeft',
  Right: 'ArrowRight',
  Esc: 'Escape',
  Space: ' ',
};

const valueKey = Object.keys(keyValue).reduce((acc, key) => {
  acc[keyValue[key]] = key;
  return acc;
}, {} as BrowserCompatKeys);

export const browserCompatKeys: { [key: string]: string } = {
  ...keyValue,
  ...valueKey,
};

/**
 * Invokes callback when a key is pressed.
 *
 * See https://keycode.info and https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values for possible key values.
 */
export const useKeyPress = (
  /**
   * comma and/or space delimited list of keys eg. 'left,right'
   */
  keysToListenTo: string,
  callback: (key: string) => any
) =>
  useEffect(() => {
    const keysToMatch = keysToListenTo
      .split(/[, ]/)
      .map((key) => key.toLowerCase());

    const handleKeyDown = ({ key: rawKey }: KeyboardEvent) => {
      if (!rawKey) {
        return;
      }
      if (
        keysToMatch.includes(rawKey.toLowerCase()) ||
        keysToMatch.includes(browserCompatKeys[rawKey]?.toLowerCase())
      ) {
        callback(rawKey);
      }
    };

    document.addEventListener('keydown', handleKeyDown, true);
    return () => document.removeEventListener('keydown', handleKeyDown, true);
  }, [callback, keysToListenTo]);
