import { forwardRef } from 'react';
import { TextLikeInput } from './text-input';
import { type TextLikeProps } from './types';

interface BaseEmailInputProps extends TextLikeProps {
  /**
   * Accept multiple comma-separated email addresses.
   */
  multiple?: boolean;

  /**
   * What type of information to autocomplete in the input.
   */
  autoComplete?: 'off' | 'email';

  /**
   * Minimum length of the email address.
   */
  minLength?: number;

  /**
   * Maximum length of the email address.
   */
  maxLength?: number;

  /**
   * Regular expression that the email must match.
   *
   * Email pattern validation is already built into the browser, so this should only be used to for
   * instance require that the email address ends with a specific domain.
   */
  pattern?: string;

  /**
   * Id of a `<datalist>` element with a list of predefined values to suggest to the user.
   */
  list?: string;

  /**
   * Inject an element into the input field, displayed at the end of the input.
   * Usually used to display a custom `Icon` or `IconButton`.
   */
  contentAfter?: React.ReactNode;
}

type ControlledProps = {
  /**
   * Value of the input.
   *
   * Makes the input controlled.
   */
  value: string;

  /**
   * Fires immediately when the input’s value is changed by the user (for example, it fires on every keystroke).
   */
  onChange: React.ChangeEventHandler<HTMLInputElement>;

  defaultValue?: never;
};

type UncontrolledProps = {
  /**
   * Default value of an uncontrolled input.
   */
  defaultValue?: string;

  /**
   * Fires immediately when the input’s value is changed by the user (for example, it fires on every keystroke).
   */
  onChange?: React.ChangeEventHandler<HTMLInputElement>;

  value?: never;
};

export type EmailInputUncontrolledProps = BaseEmailInputProps &
  UncontrolledProps;
export type EmailInputControlledProps = BaseEmailInputProps & ControlledProps;
export type EmailInputProps = BaseEmailInputProps &
  (ControlledProps | UncontrolledProps);

/**
 * EmailInput should be used whenever the user needs to enter an email address.
 *
 * It looks identical to a TextInput, but provides autocomplete, validation and
 * a suitable virtual keyboard for mobile.
 */
export const EmailInput = forwardRef<HTMLInputElement, EmailInputProps>(
  function EmailInput(
    props: EmailInputProps,
    ref: React.ForwardedRef<HTMLInputElement>
  ) {
    return (
      <TextLikeInput {...props} type="email" inputMode="email" ref={ref} />
    );
  }
);
