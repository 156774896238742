import { forwardRef } from 'react';
import { TextLikeInput } from './text-input';
import { type TextLikeProps } from './types';

interface BaseUrlInputProps extends TextLikeProps {
  /**
   * Accept multiple URLs.
   */
  multiple?: boolean;

  /**
   * What type of information to autocomplete in the input.
   *
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
   */
  autoComplete?: 'off' | 'url' | 'photo';

  /**
   * Minimum length of the URL.
   */
  minLength?: number;

  /**
   * Maximum length of the URL.
   */
  maxLength?: number;

  /**
   * Regular expression that the URL must match.
   */
  pattern?: string;

  /**
   * Id of a `<datalist>` element with a list of predefined values to suggest to the user.
   */
  list?: string;

  /**
   * Inject an element into the input field, displayed at the end of the input.
   * Usually used to display a custom `Icon` or `IconButton`.
   */
  contentAfter?: React.ReactNode;
}

type ControlledProps = {
  /**
   * Value of the input.
   *
   * Makes the input controlled.
   */
  value: string;

  /**
   * Fires immediately when the input’s value is changed by the user (for example, it fires on every keystroke).
   */
  onChange: React.ChangeEventHandler<HTMLInputElement>;

  defaultValue?: never;
};

type UncontrolledProps = {
  /**
   * Default value of an uncontrolled input.
   */
  defaultValue?: string;

  /**
   * Fires immediately when the input’s value is changed by the user (for example, it fires on every keystroke).
   */
  onChange?: React.ChangeEventHandler<HTMLInputElement>;

  value?: never;
};

export type UrlInputUncontrolledProps = BaseUrlInputProps & UncontrolledProps;
export type UrlInputControlledProps = BaseUrlInputProps & ControlledProps;
export type UrlInputProps = BaseUrlInputProps &
  (ControlledProps | UncontrolledProps);

/**
 * UrlInput should be used whenever the user needs to enter a URL.
 *
 * It looks identical to a TextInput, but provides autocomplete, validation and
 * a suitable virtual keyboard for mobile.
 */
export const UrlInput = forwardRef<HTMLInputElement, UrlInputProps>(
  function UrlInput(
    props: UrlInputProps,
    ref: React.ForwardedRef<HTMLInputElement>
  ) {
    return <TextLikeInput {...props} type="url" inputMode="url" ref={ref} />;
  }
);
