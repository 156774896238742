import React, { useMemo } from 'react';
import { BreadcrumbsProps } from '../Breadcrumbs';

type Trail = BreadcrumbsProps['trail'];

export const BreadcrumbsStructuredData: React.FC<{
  trail: Trail;
  currentTitle: string;
  pretty?: boolean;
}> = ({ trail, currentTitle, pretty }) => {
  const jsonLd = useMemo(
    () =>
      JSON.stringify(
        generateBreadcrumbsStructuredData({ trail, currentTitle }),
        null,
        pretty ? 2 : undefined
      ),
    [trail, currentTitle, pretty]
  );

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: jsonLd }}
    />
  );
};

export function generateBreadcrumbsStructuredData({
  trail,
  currentTitle,
}: {
  trail: Trail;
  currentTitle: string;
}) {
  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [...trail, { title: currentTitle, href: '' }].map(
      ({ title, href }, index) => ({
        '@type': 'ListItem',
        position: index + 1,
        name: title,
        ...(href && { item: href }),
      })
    ),
  };
}
