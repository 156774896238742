import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLayoutEffect } from '@volvo-cars/react-layout-utils';

type HTMLRef = React.MutableRefObject<HTMLElement | null>;
type HTMLMap = Map<HTMLElement, number | undefined>;
interface ContextValue {
  setRef?: (ref: HTMLRef, index?: number) => void;
  removeRef?: (ref: HTMLRef) => void;
  getIndex?: (ref: HTMLRef) => number | undefined;
  total?: number;
  getByIndex?: (index: number) => {
    ref: {
      current: HTMLElement | null;
    };
    index: number | undefined;
  } | null;
}

export const createDescendantsIndexContext = () => {
  /**
   * Unique descendants context to allow for multiple levels of Provider nesting without affecting results
   */
  const DescendantsIndexContext = createContext<ContextValue>({});
  /**
   * Descendants index Provider, encapsulates DOM element comparison logic to calculate descendant index
   */
  const DescendantsIndexProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
  }) => {
    const [nodes, setNodes] = useState<HTMLMap>(new Map());

    const setRef = useCallback((ref: HTMLRef, index?: number) => {
      if (!ref?.current) return;
      setNodes((nodes) => {
        const elem = ref?.current;
        if (!elem) return nodes;
        const newNodes = new Map(nodes);
        newNodes.set(elem, index);
        return newNodes;
      });
    }, []);

    const removeRef = useCallback((ref: HTMLRef) => {
      if (!ref?.current) return;
      setNodes((nodes) => {
        const elem = ref?.current;
        if (!elem) return nodes;
        const newNodes = new Map(nodes);
        newNodes.delete(elem);
        return newNodes;
      });
    }, []);

    useLayoutEffect(() => {
      // no need to sort if we have only one element
      if (nodes.size < 2) return;
      const newNodes = new Map(nodes);
      const nodesArray = Array.from(newNodes.keys());
      const sortedNodesArray = sortNodes(nodesArray);
      sortedNodesArray.forEach((node, i) => {
        newNodes.set(node, i);
      });
      if (!isEqual(newNodes, nodes)) {
        setNodes(newNodes);
      }
    }, [nodes]);

    const getIndex = useCallback(
      (ref: HTMLRef) => {
        return ref.current ? nodes.get(ref.current) : undefined;
      },
      [nodes]
    );

    const getByIndex = useCallback(
      (index: number) => {
        const entry = [...nodes.entries()].find(([, elementIndex]) => {
          return elementIndex === index;
        });
        if (!entry) return null;
        return { ref: { current: entry[0] }, index: entry[1] };
      },
      [nodes]
    );

    const total = nodes.size;
    const value = useMemo(() => {
      return {
        setRef,
        removeRef,
        getIndex,
        total,
        getByIndex,
      };
    }, [setRef, getIndex, removeRef, total, getByIndex]);

    return (
      <DescendantsIndexContext.Provider value={value}>
        {children}
      </DescendantsIndexContext.Provider>
    );
  };

  /**
   * A hook that consumes the Context and returns a React HMTLElement `ref` and the element index this ref is assigned to.
   */
  const useDescendantIndex = <T extends HTMLElement = HTMLElement>() => {
    const ref = useRef<T | null>(null);
    const { setRef, getIndex, removeRef, total, getByIndex } = useContext(
      DescendantsIndexContext
    );
    useLayoutEffect(() => {
      setRef?.(ref);
      return () => {
        removeRef?.(ref);
      };
    }, [setRef, removeRef]);
    return { ref, index: getIndex?.(ref), total, getByIndex };
  };
  return {
    DescendantsIndexProvider,
    useDescendantIndex,
    DescendantsIndexContext,
  };
};

export function sortNodes(nodes: HTMLElement[]) {
  return nodes.sort((a, b) => {
    const compareMask = a.compareDocumentPosition(b);

    // a comes before b, or b is child of a
    if (
      compareMask & Node.DOCUMENT_POSITION_FOLLOWING ||
      compareMask & Node.DOCUMENT_POSITION_CONTAINED_BY
    ) {
      return -1;
    }
    // b comes before a, or a is child of b
    if (
      compareMask & Node.DOCUMENT_POSITION_PRECEDING ||
      compareMask & Node.DOCUMENT_POSITION_CONTAINS
    ) {
      return 1;
    }

    return 0;
  });
}

const isEqual = (map1: HTMLMap, map2: HTMLMap) => {
  if (map1.size !== map2.size) return false;
  for (const [key, value] of map1.entries()) {
    if (value !== map2.get(key)) return false;
  }
  return true;
};
