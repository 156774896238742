import { forwardRef } from 'react';
import { cssMerge } from '@volvo-cars/css/utils';
import { Icon } from '@volvo-cars/react-icons';

export interface AccordionSummaryProps {
  hidden?: boolean;
  id?: string;
  title?: string;
  dir?: string;
  lang?: string;
  slot?: string;
  translate?: 'yes' | 'no';
  style?: React.CSSProperties;
  onFocus?: React.FocusEventHandler<HTMLElement>;
  onBlur?: React.FocusEventHandler<HTMLElement>;
  onClick?: React.MouseEventHandler<HTMLElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLElement>;
  onKeyUp?: React.KeyboardEventHandler<HTMLElement>;
  onPointerEnter?: React.PointerEventHandler<HTMLElement>;
  onPointerMove?: React.PointerEventHandler<HTMLElement>;
  onPointerDown?: React.PointerEventHandler<HTMLElement>;
  onPointerUp?: React.PointerEventHandler<HTMLElement>;
  onPointerLeave?: React.PointerEventHandler<HTMLElement>;

  /**
   * The label of the summary. Should not contain interactive elements.
   */
  children: React.ReactNode;

  /**
   * The icon to display.
   */
  icon?: React.ReactNode;

  /**
   * The placement of the icon - before or after the label.
   */
  iconPlacement?: 'start' | 'end';

  /**
   * The vertical alignment of the icon.
   */
  iconAlignment?: 'top' | 'center';

  /**
   * Custom class name, merged with existing classes.
   */
  className?: string;
}

const defaultIcon = (
  <Icon
    icon="chevron-down"
    size={16}
    className="details-open:rotate-180 transition-transform"
  />
);

/**
 * AccordionSummary is used within an [Accordion](https://developer.volvocars.com/design-system/web/?path=/docs/components-accordion-introduction--docs)
 * component as the toggle for the detailed content.
 *
 * It renders a [`summary`](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/summary) element
 * which has an implicit `button` role, meaning it does not allow any interactive children like links,
 * buttons or checkboxes.
 */
export const AccordionSummary = forwardRef<HTMLElement, AccordionSummaryProps>(
  function AccordionSummary(
    {
      children,
      className,
      iconPlacement = 'end',
      iconAlignment = 'top',
      icon: customIcon,
      ...props
    },
    ref
  ) {
    let icon =
      customIcon ||
      (iconAlignment === 'top' ? (
        // Center the icon vertically in the first line of the accordion summary
        <div className="h-1lh flex-row items-center">{defaultIcon}</div>
      ) : (
        defaultIcon
      ));
    return (
      <summary
        className={cssMerge(
          'py-16',
          iconAlignment === 'center' && 'items-center',
          iconPlacement === 'start'
            ? 'flex-row-reverse justify-end gap-x-16'
            : 'flex-row justify-between gap-x-8',
          className
        )}
        {...props}
        ref={ref}
      >
        <span>{children}</span>
        {icon}
      </summary>
    );
  }
);
