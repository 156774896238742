import { forwardRef, useState } from 'react';
import { IconButton } from '@volvo-cars/react-icons';
import { TextLikeInput } from './text-input';
import { type TextLikeProps } from './types';
import { useIds } from './use-ids';

interface BasePasswordInputProps extends TextLikeProps {
  /**
   * Password is initially visible.
   *
   * @default false
   */
  defaultPasswordVisible?: boolean;

  /**
   * Pass `current-password` to autocomplete the users current password or
   * `new-password` to autocomplete the new password in a change password form.
   */
  autoComplete?: 'current-password' | 'new-password' | 'off';

  /**
   * Regular expression that the password must match.
   */
  pattern?: string;

  /**
   * Specifies the visible width, in characters, of the input.
   */
  size?: number;

  /**
   * Minimum length of the password.
   */
  minLength?: number;

  /**
   * Maximum length of the password.
   */
  maxLength?: number;

  showPasswordLabel?: string;
}

type ControlledProps = {
  /**
   * Value of the input.
   *
   * Makes the input controlled.
   */
  value: string;

  /**
   * Fires immediately when the input’s value is changed by the user (for example, it fires on every keystroke).
   */
  onChange: React.ChangeEventHandler<HTMLInputElement>;

  defaultValue?: never;
};

type UncontrolledProps = {
  /**
   * Default value of an uncontrolled input.
   */
  defaultValue?: string;

  /**
   * Fires immediately when the input’s value is changed by the user (for example, it fires on every keystroke).
   */
  onChange?: React.ChangeEventHandler<HTMLInputElement>;

  value?: never;
};

export type PasswordInputUncontrolledProps = BasePasswordInputProps &
  UncontrolledProps;
export type PasswordInputControlledProps = BasePasswordInputProps &
  ControlledProps;
export type PasswordInputProps = BasePasswordInputProps &
  (ControlledProps | UncontrolledProps);

/**
 * PasswordInput should be used when user enters a password.
 * It lets the user show or hide the contents of the input.
 */
export const PasswordInput = forwardRef<HTMLInputElement, PasswordInputProps>(
  function PasswordInput(
    {
      id,
      defaultPasswordVisible = false,
      showPasswordLabel,
      ...props
    }: PasswordInputProps,
    ref: React.ForwardedRef<HTMLInputElement>
  ) {
    const [passwordVisible, setPasswordVisible] = useState(
      defaultPasswordVisible
    );
    const { inputId, rootId } = useIds(id);
    return (
      <TextLikeInput
        {...props}
        type={passwordVisible ? 'text' : 'password'}
        id={rootId}
        ref={ref}
        contentAfter={
          <IconButton
            icon={passwordVisible ? 'eye' : 'eye-slashed'}
            className="-mr-8"
            variant="clear"
            disabled={props.disabled}
            tabIndex={props.disabled || !showPasswordLabel ? -1 : undefined}
            {...(showPasswordLabel
              ? {
                  'aria-pressed': passwordVisible,
                  'aria-label': showPasswordLabel,
                  'aria-controls': inputId,
                }
              : {
                  'aria-label': '',
                  'aria-hidden': true,
                })}
            onClick={() => setPasswordVisible((state) => !state)}
          />
        }
      />
    );
  }
);
