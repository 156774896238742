export interface HintProps {
  children: React.ReactNode;
  id: string;
  className?: string;
}

export function Hint({ children, id, className = '' }: HintProps) {
  return (
    <p id={id} className={`micro text-secondary ${className}`.trim()}>
      {children}
    </p>
  );
}
