import { useEffect, useLayoutEffect } from 'react';

/**
 * useLayoutEffect that's safe to use with SSR
 */
const useUniversalLayoutEffect =
  typeof window !== 'undefined' ? useLayoutEffect : useEffect;

// Keep the name useLayoutEffect to work out of the box with eslint-plugin-react-hooks
export { useUniversalLayoutEffect as useLayoutEffect };
