import { useCallback, useImperativeHandle } from 'react';
import { createDescendantsIndexContext } from '@volvo-cars/react-descendants';

export interface ControlOptions {
  /**
   * Loop focus when reaching the last (or first) element
   */
  loop?: boolean;
}
export interface ControlMetaBase {
  target: {
    ref: {
      current: HTMLElement | null;
    };
    index: number | undefined;
  } | null;
  options?: ControlOptions;
  type: 'next' | 'prev' | 'first' | 'last';
}
export type ControlMeta = ControlMetaBase | null;

export interface Controls {
  next: (options?: ControlOptions) => ControlMeta;
  prev: (options?: ControlOptions) => ControlMeta;
  first: () => ControlMeta;
  last: () => ControlMeta;
}
export interface UseKeyboardNavigationResults<T> {
  /**
   * An optional ref provided by the user, will be "merged" with the ref
   * returned by the hook   */
  ref?:
    | React.ForwardedRef<T>
    | React.MutableRefObject<T | null>
    | React.RefObject<T | null>
    | null;
  /**
   * An optional `onKeyDown` handler that'll be called inside of the `onKeyDown`
   * returned by the hook
   */
  onKeyDown?: React.KeyboardEventHandler<T>;
}

function getControlsArguments({
  getByIndex,
  index,
  total,
}: {
  getByIndex: (index: number) => {
    ref: {
      current: HTMLElement | null;
    };
    index: number | undefined;
  } | null;
  total: number;
  index: number;
}): Controls {
  const nextRef = getByIndex(index + 1);
  const prevRef = getByIndex(index - 1);
  const firstRef = getByIndex(0);
  const lastRef = getByIndex(total - 1);
  return {
    next: (options = {}) => {
      return {
        target: options.loop ? nextRef || firstRef : nextRef,
        options,
        type: 'next',
      };
    },
    prev: (options = {}) => {
      return {
        target: options.loop ? prevRef || lastRef : prevRef,
        options,
        type: 'prev',
      };
    },
    first: () => {
      return { target: firstRef, type: 'first' };
    },
    last: () => {
      return { target: lastRef, type: 'last' };
    },
  };
}

export function createKeyboardNavigation() {
  const { useDescendantIndex, DescendantsIndexProvider } =
    createDescendantsIndexContext();

  function useAdvancedKeyboardNavigation<T extends HTMLElement = HTMLElement>(
    controls: (controls: Controls) => Record<string, ControlMeta>,
    { ref, onKeyDown }: UseKeyboardNavigationResults<T> = {}
  ): {
    ref: React.MutableRefObject<T | null>;
    onKeyDown: React.KeyboardEventHandler<T>;
  } {
    const {
      ref: descendantRef,
      index,
      total,
      getByIndex,
    } = useDescendantIndex<T>();

    useImperativeHandle(ref, () => descendantRef.current);

    return {
      ref: descendantRef,
      onKeyDown: useCallback(
        (event) => {
          onKeyDown?.(event);
          if (
            !getByIndex ||
            typeof index !== 'number' ||
            typeof total !== 'number'
          ) {
            return;
          }
          const controlsArgs = getControlsArguments({
            getByIndex,
            index,
            total,
          });
          const eventKeys = controls(controlsArgs);
          const result = eventKeys[event.key];
          if (result?.target?.ref) {
            event.preventDefault();
            result.target?.ref?.current?.focus();
          }
        },
        [getByIndex, index, total, controls, onKeyDown]
      ),
    };
  }

  function useKeyboardNavigation<T extends HTMLElement>({
    orientation = 'horizontal',
    direction,
    ref,
    onKeyDown,
  }: UseKeyboardNavigationResults<T> & {
    /**
     * Orientation of the keyboard navigation, either `horizontal` or `vertical`
     * @default 'horizontal'
     */
    orientation?: 'horizontal' | 'vertical';
    /**
     * Direction of the text, `rtl` or `ltr`. What's usually specified on a
     * parent element `dir` attribute or CSS property `direction`.  If not
     * specified, `getComputedStyle` will be used to compute the direction. Only
     * relevant when orientation is `horizontal`
     * @default getComputedStyle().direction
     */
    direction?: 'ltr' | 'rtl';
  } = {}) {
    const props = useAdvancedKeyboardNavigation(
      ({ next, prev, first, last }) => {
        const firstTarget = first();
        const firstElement = firstTarget?.target?.ref.current;

        const isVertical = orientation === 'vertical';

        const dir =
          direction ||
          ((firstElement && !isVertical
            ? getComputedStyle(firstElement).direction || 'ltr'
            : 'ltr') as 'ltr' | 'rtl');

        const isRtl = dir === 'rtl';

        return {
          [isVertical ? 'ArrowDown' : isRtl ? 'ArrowLeft' : 'ArrowRight']: next(
            {
              loop: true,
            }
          ),
          [isVertical ? 'ArrowUp' : isRtl ? 'ArrowRight' : 'ArrowLeft']: prev({
            loop: true,
          }),
          Home: firstTarget,
          End: last(),
        };
      },
      { ref, onKeyDown }
    );
    return props;
  }

  return {
    KeyboardNavigationProvider: DescendantsIndexProvider,
    useAdvancedKeyboardNavigation,
    useKeyboardNavigation,
  };
}
