import { createContext, useCallback, useContext, useState } from 'react';
import queries from '@volvo-cars/css/media-queries';
import { useMediaQuery } from './utils/use-media-query';

const Context = createContext({} as AccordionControllerContextValue);

interface AccordionControllerContextValue {
  isOpen?: (id: string, defaultOpen?: boolean) => boolean;
  toggle?: (id: string, open?: boolean) => void;
}

export interface AccordionControllerProps {
  children: React.ReactNode;
  /**
   * @default true
   *
   * If `true`, only one item can be open at a time.
   * If `until-lg`, only one item can be open at a time unless the viewport is at least large.
   * To create a non-exclusive accordion, remove the `AccordionController`.
   */
  exclusive?: boolean | 'until-lg';
}

export function AccordionController({
  exclusive = true,
  children,
}: AccordionControllerProps) {
  // Before any accordion has been explicitly toggled, we use `null` to
  // signify that the `defaultOpen` parameters should be used in `isOpen`.
  const [openIds, setOpenIds] = useState<Set<string> | null>(null);
  const isLarge = useMediaQuery(queries.lg);
  const allowMultiple = !exclusive || (exclusive === 'until-lg' && isLarge);

  const isOpen = useCallback(
    (id: string, defaultOpen?: boolean) =>
      openIds ? openIds.has(id) : defaultOpen || false,
    [openIds]
  );

  const toggle = useCallback(
    (id: string, open?: boolean) => {
      setOpenIds((prev) => {
        const set = new Set(prev || []);
        if (!open && set.has(id)) {
          set.delete(id);
        } else if (open) {
          if (allowMultiple) {
            set.add(id);
          } else {
            set.clear();
            set.add(id);
          }
        }
        return set;
      });
    },
    [allowMultiple]
  );

  return (
    <Context.Provider
      value={{ isOpen: allowMultiple ? undefined : isOpen, toggle }}
    >
      {children}
    </Context.Provider>
  );
}

export const useAccordionController = () => useContext(Context);
