import { forwardRef, useCallback, useId } from 'react';
import { cssMerge } from '@volvo-cars/css/utils';
import { useAnimatedDetails } from '@volvo-cars/react-headless';
import { useAccordionController } from './accordion-controller';

interface BaseAccordionDetailsProps {
  hidden?: boolean;
  id?: string;
  title?: string;
  dir?: string;
  lang?: string;
  slot?: string;
  translate?: 'yes' | 'no';
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler<HTMLDetailsElement>;
  onPointerEnter?: React.PointerEventHandler<HTMLDetailsElement>;
  onPointerMove?: React.PointerEventHandler<HTMLDetailsElement>;
  onPointerDown?: React.PointerEventHandler<HTMLDetailsElement>;
  onPointerUp?: React.PointerEventHandler<HTMLDetailsElement>;
  onPointerLeave?: React.PointerEventHandler<HTMLDetailsElement>;

  /**
   * The content of the accordion. The first child should be the `AccordionSummary` component.
   */
  children: React.ReactNode;

  /**
   * Custom class name, merged with existing classes.
   */
  className?: string;

  /**
   * Called when the accordion is toggled.
   */
  onToggle?: (open: boolean) => void;

  /**
   * Called when the toggle animation is finished.
   */
  onAnimationFinish?: (open: boolean) => void;
}

interface UncontrolledProps {
  /**
   * If true, the accordion will be open by default but can then be toggled freely by the user.
   */
  defaultOpen?: boolean;

  open?: never;
}

interface ControlledProps {
  /**
   * If set, the accordion will be controlled by the value of this prop.
   * Must be used together with the `onToggle` prop.
   */
  open?: boolean;

  defaultOpen?: never;
}

export type AccordionDetailsUncontrolledProps = BaseAccordionDetailsProps &
  UncontrolledProps;
export type AccordionDetailsControlledProps = BaseAccordionDetailsProps &
  ControlledProps;
export type AccordionDetailsProps = BaseAccordionDetailsProps &
  (ControlledProps | UncontrolledProps);

/**
 * Used to toggle the visibility of additional content using the native `details` and `summary` elements.
 *
 * When `AccordionDetails` is closed all its children except the [AccordionSummary](https://developer.volvocars.com/design-system/web/?path=/docs/components-accordion-accordionsummary--docs) will be hidden.
 *
 * `AccordionDetails` can either be
 *
 * - uncontrolled (default), where the state is managed by the browser and the `defaultOpen` prop
 * - controlled with the `open` and `onToggle` props
 * - controlled by a parent [AccordionController](https://developer.volvocars.com/design-system/web/?path=/docs/components-accordion-accordion--docs)
 */
export const AccordionDetails = forwardRef<
  HTMLDetailsElement,
  AccordionDetailsProps
>(function AccordionDetails(
  {
    className,
    children,
    defaultOpen,
    open,
    onToggle,
    onAnimationFinish,
    ...props
  }: AccordionDetailsProps,
  ref
) {
  const id = useId();
  const { toggle: controllerToggle, isOpen: controllerIsOpen } =
    useAccordionController();
  const handleToggle = useCallback(
    (open: boolean) => {
      if (typeof controllerToggle === 'function') {
        controllerToggle(id, open);
      }
      if (onToggle) {
        onToggle(open);
      }
    },
    [id, onToggle, controllerToggle]
  );
  return (
    <details
      className={cssMerge('border-b', className)}
      {...(defaultOpen ? { open: true } : {})}
      {...useAnimatedDetails({
        ref,
        onAnimationFinish,
        open:
          typeof open === 'boolean'
            ? open
            : typeof controllerIsOpen === 'function'
            ? controllerIsOpen(id, defaultOpen)
            : undefined,
        onToggle: handleToggle,
      })}
      {...props}
    >
      {children}
    </details>
  );
});
