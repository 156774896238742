import { forwardRef, useEffect, useState } from 'react';
import { type GlobalHTMLAttributes } from './types';

interface BaseRangeSliderProps extends GlobalHTMLAttributes {
  /**
   * The name of the input to use when submitting the form.
   */
  name?: string;

  /**
   * Id of a form element that this input should be associated with. Defaults to the containing
   * form element.
   */
  form?: string;

  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  onKeyUp?: React.KeyboardEventHandler<HTMLInputElement>;

  /**
   * The minimum value for the input.
   */
  min?: number;

  /**
   * The maximum value for the input.
   */
  max?: number;

  /**
   * The step interval between values.
   */
  step?: number;

  /**
   * Id of a `<datalist>` element with a list of predefined values to suggest to the user.
   */
  list?: string;
}

type ControlledProps = {
  /**
   * Value of the input.
   *
   * Makes the input controlled.
   */
  value: number;

  /**
   * Fires immediately when the input’s value is changed by the user (for example, it fires on every keystroke).
   */
  onChange: React.ChangeEventHandler<HTMLInputElement>;

  defaultValue?: never;
};

type UncontrolledProps = {
  /**
   * Default value of an uncontrolled input.
   */
  defaultValue?: string;

  /**
   * Fires immediately when the input’s value is changed by the user (for example, it fires on every keystroke).
   */
  onChange?: React.ChangeEventHandler<HTMLInputElement>;

  value?: never;
};

export type RangeSliderUncontrolledProps = BaseRangeSliderProps &
  UncontrolledProps;
export type RangeSliderControlledProps = BaseRangeSliderProps & ControlledProps;
export type RangeSliderProps = BaseRangeSliderProps &
  (ControlledProps | UncontrolledProps);

/**
 * `RangeSlider` is shipped as a standalone React component without the standard label/hint/error configuration of most inputs,
 * as it's envisaged that its primary use will be in custom components. Due to the [fragmented browser landscape](https://github.com/w3c/csswg-drafts/issues/4410)
 * of native range inputs, we need to control aspects of the visual style with JavaScript - so we do not ship a HTML-only version of this component.
 * <hr/>
 * `RangeSlider` can be used uncontrolled (native), or controlled. To make a `RangeSlider` controlled, set both the `value` prop and the `onChange` handler.
 */
export const RangeSlider = forwardRef<HTMLInputElement, RangeSliderProps>(
  function RangeSlider(
    props: RangeSliderProps,
    ref: React.ForwardedRef<HTMLInputElement>
  ) {
    const { min = 0, max = 100, value, onChange, ...restProps } = props;
    const [sliderValue, setSliderValue] = useState(
      value !== undefined ? value : min
    );
    const widthPercent =
      (((sliderValue <= max ? sliderValue : max) - min) / (max - min)) * 100;

    useEffect(() => {
      if (typeof value !== 'undefined') {
        setSliderValue(value <= max ? Number(value) : max);
      } else {
        setSliderValue(max / 2); // Browsers set the thumb to half the range by default when value is not set. We need to make our filled track follow suit
      }
    }, [value, max]);

    const updateSliderValue = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (typeof value === 'undefined') {
        setSliderValue(Number(e.target.value));
      }
      if (onChange) {
        onChange(e);
      }
    };

    return (
      <input
        {...restProps}
        type="range"
        className="range"
        ref={ref}
        min={min}
        max={max}
        value={sliderValue}
        onChange={updateSliderValue}
        style={
          {
            ...props.style,
            '--range-value-percent': `${widthPercent}%`,
          } as React.CSSProperties
        }
      />
    );
  }
);
