import { useEffect, useState } from 'react';

export function useMediaQuery(query: string): boolean {
  const [state, setState] = useState(false);
  useEffect(() => {
    if (!window.matchMedia) return;
    setState(window.matchMedia(query).matches);
    const listener = (mediaQueryEvent: MediaQueryListEvent) =>
      setState(mediaQueryEvent.matches);
    const mq = window.matchMedia(query);
    mq.addListener(listener);
    return () => {
      mq.removeListener(listener);
    };
  }, [query]);

  return state;
}
