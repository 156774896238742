import { useEffect, useRef } from 'react';

/**
 * Note: It’s possible that in the future React will provide a usePrevious Hook out of the box since it’s a relatively common use case.
 * https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
 **/

export const usePrevious = <T>(value: T) => {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};
